<template>
  <FileInput
    v-bind="$attrs"
    :fileChangeHandler="(file) => fileChangeHandler(file)"
    :loadingStatus="loadingStatus()"
    :image_url="assetId()"
    :image_url_brief="briefAssetId()"
    :previewUrl="previewUrl()"
    :previewUrlBrief="previewUrlBrief()"
    :diffIdentifier="diffIdentifier"
    :isAssetApproved="assetApproved"
    :canFinalizeAsset="$attrs.canFinalizeAsset"
    @addBriefToAsset="
      (previewUrl, briefUrl, isAssetApproved) => copyFile(previewUrl, briefUrl, 0, isAssetApproved)
    "
  />
</template>
<script>
import FileInput from '@/components/inputs/FileInput';
import { mapActions } from 'vuex';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { get } from 'lodash';
export default {
  name: 'FileInputWrapper',
  components: {
    FileInput,
  },
  data() {
    return {
      FILE_UPLOADING_STATUS,
    };
  },
  computed: {
    data() {
      return this.$attrs.data;
    },
    assetApproved() {
      return get(this.data, `${this.fileIdentifier}.assetApproved`, false);
    },
    subPath() {
      return this.$attrs.subPath;
    },
    dataPath() {
      return this.$attrs.dataPath;
    },
    imageKey() {
      return this.$attrs.isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    },
    diffIdentifier() {
      return `${this.fileIdentifier}.${this.imageKey}`;
    },
    fileIdentifier() {
      return `${this.subPath}.${this.dataPath}`;
    },
    assetSubPath() {
      return this.$attrs.assetSubPath;
    },
    assetUrlIdentifier() {
      return `${this.assetSubPath || this.subPath}.${this.dataPath}`;
    },
  },
  methods: {
    ...mapActions([
      'importSingleAssetActionByIdentifier',
      'deleteSingleResourceAssetActionByIdentifier',
      'copySingleResourceAssetActionByIdentifier',
    ]),
    loadingStatus() {
      const currentLoadingStatus = get(this.$attrs.signedUrls, this.assetUrlIdentifier, {})
        .loadingStatus;
      return currentLoadingStatus == FILE_UPLOADING_STATUS.IN_PROGRESS;
    },
    currentAssetId() {
      if (this.$attrs.isAssetUploadRequired) {
        return get(this.$attrs.data, this.fileIdentifier, {}).assetId;
      }
      return get(this.$attrs.data, this.fileIdentifier, {}).brief.assetId;
    },
    assetId() {
      if (this.$attrs.isAssetUploadRequired) {
        return get(this.$attrs.data, this.fileIdentifier, {}).assetId;
      }
      return '';
    },
    previewUrl() {
      if (this.$attrs.isAssetUploadRequired) {
        return get(this.$attrs.signedUrls, this.assetUrlIdentifier, {}).assetSignedUrl;
      }
      return '';
    },
    briefAssetId() {
      return get(this.$attrs.data, this.fileIdentifier, {})?.brief?.assetId ?? '';
    },
    previewUrlBrief() {
      return get(this.$attrs.signedUrls, this.assetUrlIdentifier, {})?.brief?.assetSignedUrl ?? '';
    },
    fileChangeHandler(file) {
      if (file) {
        this.importSingleAssetActionByIdentifier({
          payload: file,
          identifier: this.fileIdentifier,
          assetUrlIdentifier: this.assetUrlIdentifier,
        });
      } else {
        this.deleteSingleResourceAssetActionByIdentifier({
          assetId: this.currentAssetId(),
          identifier: this.fileIdentifier,
          subPath: this.subPath,
          assetUrlIdentifier: this.assetUrlIdentifier,
        });
      }
    },
    copyFile(signedUrl, briefAssetId, isAssetApproved) {
      this.copySingleResourceAssetActionByIdentifier({
        signedUrl,
        briefAssetId,
        isAssetApproved,
        identifier: this.fileIdentifier,
        assetUrlIdentifier: this.assetUrlIdentifier,
      });
    },
  },
  inheritAttrs: false,
};
</script>
